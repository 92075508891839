export const APP_EVENTS = {
	READY: "APP_READY",
	ANALYTICS: "APP_ANALYTICS",
	SCREENSHOT: "SCREENSHOT",
	CUSTOM_AR_QR_MODAL: "CUSTOM_AR_QR_MODAL",
	MOUSE_MOVE: "MOUSE_MOVE",
};

export const TAG_EVENTS = {
	INIT: "TAG_INIT",
	TERMINATE: "TAG_TERMINATE",
	UPDATE: "TAG_UPDATE",
	CLOSE_AR_QR_MODAL: "CLOSE_AR_QR_MODAL",

	//undocumented/debug events:
	EXPORT_GLTF: "EXPORT_GLTF",
	PRINT_STRUCTURE: "PRINT_STRUCTURE",
};
